import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import { store } from "./store";
import * as Sentry from "@sentry/vue";
import { createApp } from 'vue'
import Notifications from '@kyvg/vue3-notification'

const app = createApp(App)

if (process.env.NODE_ENV !== "development") {
  Sentry.init({
    app,
    dsn: "https://dac8400ba30f48c2bccd251c0486e26c@o137032.ingest.sentry.io/5910561",
    environment: 'production',
    debug: process.env.ENVIRONMENT !== 'production',
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
  });
}
else {
  app.config.errorHandler = (err, vm, info) => {
    console.error(`Error in ${info}: "${err.toString()}"`, vm);
  };
}

app
  .use(vuetify)
  .use(Notifications)
  .use(router)
  .use(store)
  .mount('#app')