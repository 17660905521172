import {
  createRouter,
  createWebHistory
} from 'vue-router'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      name: "privacy-policy",
      path: "/privacy-policy",
      component: () => import("./views/PrivacyPolicy.vue")
    },
    {
      name: "home",
      path: "/:inviteUuid?",
      component: () => import("./views/Home.vue")
    },
    {
      name: "register",
      path: "/register/:email?",
      component: () => import("./views/Register.vue")
    },
    {
      name: "invitation",
      path: "/invitation/:inviteUuid?",
      component: () => import("./views/Register.vue")
    },
    {
      name: "approval",
      path: "/approval/:ssoAuthToken?",
      component: () => import("./views/Home.vue")
    },
    {
      name: "reset",
      path: "/reset/:code?",
      component: () => import("./views/Reset.vue")
    },
    {
      name: "token",
      path: "/token/:token",
      component: () => import("./views/Token.vue")
    },
    {
      name: "verify",
      path: "/verify/:code",
      component: () => import("./views/Verify.vue")
    },
    {
      name: "logout",
      path: "/logout",
      component: () => import("./views/Logout.vue")
    },
  ]
});

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message)) {
    window.location.reload()
  }
})

export default router;
