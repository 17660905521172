import https from "https";
import http from "http";
import * as URL from "url";

const api = url => {
  return URL.substring(0, 7) === "http://" ? http : https;
};

const checkAuthFail = (
  response,
  callbackAuthFail,
  callbackError,
  callbackFail
) => {
  if (!response) {
    return false;
  }
  const { statusCode } = response;
  if (statusCode === 403 || statusCode === 401) {
    if (typeof callbackAuthFail === "function") {
      callbackAuthFail(statusCode);
    }
    else {
      triggerError(`auth-${statusCode}`, callbackError, callbackFail);
    }
    return true;
  }
  else {
    return false;
  }
};

const options = (url, additional = {}) => {
  const parsedUrl = URL.parse(url);
  const options = {
    ...additional,
    hostname: parsedUrl.hostname,
    path: parsedUrl.search ? `${parsedUrl.pathname}${parsedUrl.search}` : parsedUrl.pathname,
    headers: {
      ["Content-Type"]: "application/json"
    }
  };
  if (parsedUrl.port !== "") {
    options.port = parsedUrl.port;
  }
  return options;
};

const processResponse = (
  response,
  callbackSuccess,
  callbackFail,
  callbackError,
  callbackAuthFail
) => {
  const chunks_of_data = [];

  response.on("data", fragments => {
    chunks_of_data.push(fragments);
  });

  response.on("end", () => {
    const response_body = Buffer.concat(chunks_of_data);
    let responseJson;
    try {
      responseJson = JSON.parse(response_body.toString());
    }
    catch (e) {
      return triggerError('JSON-FAIL', callbackError, callbackFail);
    }
    if (
      !checkAuthFail(
        responseJson,
        callbackAuthFail,
        callbackError,
        callbackFail
      )
    ) {
      triggerResponse(responseJson, callbackSuccess, callbackFail);
    }
  });

  response.on("error", error => {
    if (!checkAuthFail(error, callbackAuthFail, callbackError, callbackFail)) {
      triggerError(error, callbackError, callbackFail);
    }
  });
};

const processUrl = (url) => {
  //Run if code block if not on local OR not testing SSO
  if(process.env.NODE_ENV !== 'development' || process.env.SSO_LOCAL_DEV !== 'true'){
    if (window.location.port === "8069") {
      return `http://${window.location.hostname}:8039/v1/${url}`;
    }
    else {
      return `/v1/${url}`;
      }
  }
  else {
    return `/v1/${url}`;
  }
};

const triggerError = (error, callbackError, callbackFail) => {
  if (callbackError === true) {
    callbackFail(error);
  }
  else if (typeof callbackError === "function") {
    callbackError(error);
  }
};

const triggerResponse = (
  response,
  callbackSuccess,
  callbackFail,
  callbackError
) => {
  const { status, data } = response;
  if (status === "success") {
    if (typeof callbackSuccess === "function") {
      callbackSuccess(data);
    }
  }
  else if (typeof callbackFail === "function") {
    callbackFail(data);
  }
  else if (typeof callbackError === "function") {
    callbackError(data);
  }
};

const deleteMethod = (
  url,
  data,
  callbackSuccess,
  callbackFail,
  callbackError,
  callbackAuthFail
) => {
  return write(
    "DELETE",
    url,
    data,
    callbackSuccess,
    callbackFail,
    callbackError,
    callbackAuthFail
  );
};

const get = (
  url,
  callbackSuccess,
  callbackFail,
  callbackError,
  callbackAuthFail
) => {

  fetch(processUrl(url), {
    method: 'GET',
    redirect: 'follow',
    credentials: "include"
  })
    .then(async (response) => {
      const responseJson = await response.json();
      if (
        !checkAuthFail(
          responseJson,
          callbackAuthFail,
          callbackError,
          callbackFail
        )
      ) {
        triggerResponse(responseJson, callbackSuccess, callbackFail);
      }
    })
    .catch((error) => {
      if (!checkAuthFail(error, callbackAuthFail, callbackError, callbackFail)) {
        triggerError(error, callbackError, callbackFail);
      }
    });
};

const post = (
  url,
  data,
  callbackSuccess,
  callbackFail,
  callbackError,
  callbackAuthFail
) => {
  return write(
    "POST",
    url,
    data,
    callbackSuccess,
    callbackFail,
    callbackError,
    callbackAuthFail
  );
};

const put = (
  url,
  data,
  callbackSuccess,
  callbackFail,
  callbackError,
  callbackAuthFail
) => {
  return write(
    "PUT",
    url,
    data,
    callbackSuccess,
    callbackFail,
    callbackError,
    callbackAuthFail
  );
};

const upload = (
  url,
  data,
  callbackSuccess,
  callbackFail,
  callbackError,
  callbackAuthFail
) => {
  const formData = new FormData();
  for (const key of Object.keys(data)) {
    formData.append(key, data[key]);
  }

  fetch(processUrl(url), {
    method: "POST",
    headers: {},
    body: formData,
    credentials: "include"
  })
    .then(response => response.json())
    .then(response => {
      if (
        !checkAuthFail(
          response,
          callbackAuthFail,
          callbackError,
          callbackFail
        )
      ) {
        triggerResponse(response, callbackSuccess, callbackFail);
      }
    })
    .catch(error => {
      if (!checkAuthFail(error, callbackAuthFail, callbackError, callbackFail)) {
        triggerError(error, callbackError, callbackFail);
      }
    });
};

const write = (
  method,
  url,
  data,
  callbackSuccess,
  callbackFail,
  callbackError,
  callbackAuthFail
) => {
  fetch(processUrl(url), {
    method,
    headers: {
      'Content-Type': 'application/json'
    },
    redirect: 'follow',
    credentials: "include",
    body: JSON.stringify(data)
  })
    .then(async (response) => {
      const responseJson = await response.json();
      if (
        !checkAuthFail(
          responseJson,
          callbackAuthFail,
          callbackError,
          callbackFail
        )
      ) {
        triggerResponse(responseJson, callbackSuccess, callbackFail);
      }
    })
    .catch((error) => {
      console.error(error);
      if (!checkAuthFail(error, callbackAuthFail, callbackError, callbackFail)) {
        triggerError(error, callbackError, callbackFail);
      }
    });
};

export default {
  delete: deleteMethod,
  get,
  post,
  put,
  upload,
  postPromise: (url, data, options) => {
    return new Promise((resolve, reject) => {
      post(
        url,
        data,
        response => {
          resolve(response);
        },
        () => {
          if (options.allowFail) {
            resolve();
          }
          else {
            reject();
          }
        },
        () => {
          reject();
        }
      );
    });
  }
};
